import React from "react";
import appleqr from "../../assets/qr/appstore.png";
import googleqr from "../../assets/qr/playstore.png";
import playStore from "../../assets/googleplay.png";
import appStore from "../../assets/appstore.png";
import phoneImgg from "../../assets/new/phoneImgg.png";
import { Row, Col } from "antd";
import { useNavigate } from "react-router";

export default function AppDownload() {
  let navigate = useNavigate();
  return (
    <Row className="downloadapp" id="app-download">
      <Col span={11} style={{ margin: "auto 0" }} className="colPadding">
        <h2>
          <span>LAST NED </span>
          APPEN VÅR NÅ
        </h2>
        <p className=" pb-4 pt-1">
          Hev din opplevelse med netthandel med
          <i>
            <b>TD Ordering-lojalitetsprogrammet</b>
          </i>
          , tilgjengelig både i
          <i>
            <b>
              {" "}
              <u>
                <a
                  href="https://apps.apple.com/in/app/tree-drive/id6444897318"
                  target="_blank"
                >
                  Apple Store
                </a>
              </u>
            </b>
          </i>{" "}
          og{" "}
          <i>
            <b>
              <u>
                <a
                  href="https://play.google.com/store/apps/details?id=com.omvadata.treedriveuser"
                  target="_blank"
                >
                  Google Play Store
                </a>
              </u>
            </b>
          </i>
          . Vår brukervennlige grensesnitt sikrer enkel navigering, fra bestilling til å gi digitale TFL-kort for matkjøp. Tjen TFL-mynter med hver bestilling, fra øyeblikket du registrerer deg. Del appen med dine kjære for å låse opp enda flere belønninger.<br />
          <br />
          Ikke gå glipp av disse fordelene – last ned appen nå og oppdag en verden av fordeler som venter på deg!
        </p>
        <div className="d-flex align-items-center" id="downloadApp">
          <div
            className="d-flex flex-column bg-white p-2"
            style={{ marginRight: 20 }}
          >
            <a
              href="https://apps.apple.com/in/app/tree-drive/id6444897318"
              target="_blank"
            >
              <img src={appStore} />
            </a>
            <br />
            <img src={appleqr} />
          </div>
          <div className="d-flex flex-column bg-white p-2">
            <a
              href="https://play.google.com/store/apps/details?id=com.omvadata.treedriveuser"
              target="_blank"
            >
              <img src={playStore} />
            </a>{" "}
            <br />
            <img src={googleqr} />
          </div>
        </div>
        <br />
        <p>
          {" "}
          <i>
            Sulten slår til, må spise nå:&nbsp;
            <strong>
              <a
                onClick={() => {
                  navigate("/restaurants");
                }}
              >
                {" "}
                <u>Finn restauranter i nærheten</u>
              </a>
            </strong>{" "}
          </i>
        </p>
        <br />
        <p>
          {" "}
          *
          <u>
            <i>Vilkår og betingelser gjelder</i>
          </u>
        </p>
      </Col>
      <Col span={12} align="middle" className="d-flex justify-content-end">
        <img
          src={phoneImgg}
          width="100%"
          className=" handImg"
          data-aos="fade"
          data-aos-duration="8000"
        />
      </Col>
    </Row>
  );
}
