import RestaurantList from "./RestaurantList";
import React, { useState } from "react";
import { Input, Collapse } from "antd";
const { TextArea } = Input;
const { Panel } = Collapse;
const Checkout = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
      <RestaurantList />
  );
};
export default Checkout;
