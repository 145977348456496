import React from "react";
import BottomFooter from "../Footer/BottomFooter";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";
import { Space, Table, Tag } from "antd";
import { isMobile } from "react-device-detect";

const dataSource = [
  {
    process: " Order Edit /Cancellation/Compensation/Payment Failure",
    paymentmethod: "Vipps",
    refundprocess: "Source",
    tat: "5-7 Business Days"
  },
  {
    process: " Order Edit /Cancellation/Compensation/Payment Failure",
    paymentmethod: "Debit/Credit Cards",
    refundprocess: "Source",
    tat: "5-7 Business Days"
  },
  {
    process: " Order Edit /Cancellation/Compensation/Payment Failure",
    paymentmethod: "TFL Gift Card",
    refundprocess: "Source",
    tat: "24 Hours"
  }
];

const columns = [
  {
    title: "Process",
    dataIndex: "process",
    key: "process"
  },
  {
    title: "Payment Method",
    dataIndex: "paymentmethod",
    key: "paymentmethod"
  },
  {
    title: "Refund Process",
    dataIndex: "refundprocess",
    key: "refundprocess"
  },
  {
    title: "TAT",
    dataIndex: "tat",
    key: "tat"
  }
];

export default function Cancellation() {
  return (
    <>
      {" "}
      {!isMobile && <PrivacyHeader />}
      <section className="mission  p-5">
        <div className="container">
          <div className="row privacy-header text-center d-flex justify-content-center">
            <h2 className="display-5 mb-4 ">
              {" "}
              Sales T&C and Cancellation Policy
            </h2>
            <i>Version 2.0 revised October 2015. Updated January 2018</i>
          </div>
        </div>
        <div className="container nav_bg">
          <div className="row privacyText">
            <div className="col-10 mx-auto privacyText">
              <div className="row py-4 privacyText">
                <p>
                  This purchase is governed by the below standard terms and
                  conditions of sale for consumer purchases of goods over the
                  Internet. Consumer purchases over the Internet are mainly
                  regulated by the Agreements Act, the Consumer Purchases Act,
                  the Marketing Act, the Right of Withdrawal Act and the Trade
                  Act, and these laws give the consumer mandatory rights. The
                  laws are available on{" "}
                  <a href="https://lovdata.no/" className="privacyLink">
                    www.lovdata.no&nbsp;
                  </a>
                  .{" "}
                </p>
                <p>
                  The terms of this Agreement shall not be understood as any
                  limitation of the statutory rights but set out the parties'
                  most important rights and obligations for the trade. The terms
                  and conditions of the sale have been prepared and recommended
                  by the Norwegian Consumer Protection Authority. For a better
                  understanding of these terms and conditions of sale, see{" "}
                  <u>the Consumer Protection Authority's guide</u>.
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">1. Agreement</h4>
                <div>
                  <p>
                    The agreement consists of these terms and conditions of
                    sale, cancellations given in the order solution and any
                    specially agreed terms. In the event of any conflict between
                    the information, what is specifically spoken between the
                    parties takes precedence, provided that it does not
                    contradict mandatory legislation.
                  </p>
                  <p>
                    The agreement will also be completed by relevant legal
                    provisions regulating the purchase of goods between traders
                    and consumers.
                  </p>
                </div>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">2. Parties </h4>
                <div>
                  <ol>
                    <li>
                      <p>
                        Platform: &nbsp;
                        <a href="https://treedrive.no" className="privacyLink">
                          treedrive.no;
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        E-Mail: &nbsp;
                        <a
                          href="mailto: kontakt@treedrive.no"
                          className="privacyLink"
                        >
                          kontakt@treedrive.no;
                        </a>
                      </p>
                    </li>
                    {/* <li>OM RESTAURANT CLOUD ID: ORC Avd. 10054</li> */}
                  </ol>
                  <p>
                    and is referred to in the following as the seller/seller.
                  </p>
                  {/* <p>
                    The Buyer is the consumer who makes the order and is
                    referred to in the following as the Buyer/Buyer.
                  </p>
                  <p>
                    <b>PARTNER</b>&nbsp;OM DATA AS (OM RESTAURANT CLOUD) is a
                    technology provider company under which you can place your
                    orders with ease and avail other services as well.
                  </p> */}
                </div>

                <div className="row privacyText">
                  <h4 className="privacyHeading">3. Taken</h4>
                  <p>
                    The stated price for the item and services is the total
                    price the buyer will pay. This price includes all taxes and
                    additional costs. Additional costs that the seller before
                    the purchase has not informed about, the buyer shall not
                    bear.
                  </p>
                </div>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">4. Agreement conclusion</h4>
                <p>
                  The agreement is binding on both parties once the buyer has
                  sent their order to the seller.
                </p>
                <p>
                  However, the agreement is not binding if there have been
                  typing or typing errors in the offer from the seller in the
                  order solution in the online store or in the buyer's order,
                  and the other party then or should have realized that such an
                  error occurred.
                </p>
                <p>
                  Table of contents:
                  <ol>
                    <li>The Agreement</li>
                    <li>The parties</li>
                    <li>Taken</li>
                    <li>Agreement conclusion</li>
                    <li>Payment</li>
                    <li>Delivery</li>
                    <li>The risk to the item</li>
                    <li>Right of withdrawal</li>
                    <li>
                      Delay and non-delivery - buyer's rights and deadline for
                      filing claims
                    </li>
                    <li>
                      Lack of the item - buyer's rights and warranty deadline
                    </li>
                    <li>Seller's rights in the event of buyer's default</li>
                    <li>Guaranteed</li>
                    <li>Personal data</li>
                    <li>Conflict resolution</li>
                  </ol>
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">5. Payment</h4>
                <p>
                  The seller may claim payment for the item from the time it is
                  placed in the online order and the item is sent from the
                  seller to the buyer on the same day.
                </p>
                <p>
                  If the buyer uses a credit or debit card at the time of
                  payment, the seller can reserve the purchase price on the card
                  at the time of booking. The card will be charged on the same
                  day the item is shipped.
                </p>
                <p>
                  When paying by invoice, the invoice to the buyer is issued
                  upon shipment of the item. The payment deadline is stated in
                  the invoice and is at least 7 days from receipt, this service
                  is only for certain users and is not a standard service.
                </p>
                <p>
                  Buyers under the age of 18 cannot pay with the following
                  invoice.
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">6. Delivery</h4>
                <p>
                  Delivery is made when the buyer, or his representative, has
                  taken over goods (food & drink as well as delivery costs).
                  Delivery costs do not apply to self-collection.
                </p>
                <p>
                  If the delivery time is not stated in the order solution, the
                  seller shall deliver the item to the buyer without undue delay
                  and no later than the same day after the order from the
                  customer. The item shall be delivered to the buyer unless
                  otherwise specifically agreed between the parties.
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">7. The risk to the item</h4>
                <p>
                  The risk of the goods passes to the buyer when he, or his
                  representative, has had the goods delivered in accordance with
                  clause 6.
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">8. Right of withdrawal</h4>
                <p>
                  Unless the agreement is exempt from the right of withdrawal,
                  the buyer may cancel the purchase of the item in accordance
                  with the Right of Withdrawal Act.
                </p>
                <p>
                  The buyer must notify the seller of the use of the right of
                  withdrawal within 14 days from the time the deadline begins to
                  run. The deadline includes all calendar days. If the deadline
                  ends on a Saturday, public holiday or holiday day, the
                  deadline is extended to the nearest working day.
                </p>
                <p>
                  The withdrawal period is considered complied with if
                  notification has been sent before the expiry of the deadline.
                  The Buyer has the burden of proof that the right of withdrawal
                  has been exercised, and the notification should therefore take
                  place in writing (withdrawal form, e-mail or letter).
                </p>
                <p>
                  The grace period begins to run:
                  <ul>
                    <li>
                      When purchasing single standing goods, the withdrawal
                      period will run from the day after the item(s) have been
                      received.
                    </li>
                    <li>
                      If a subscription is sold, or involves the agreement
                      regular delivery of identical goods, the deadline runs
                      from the day after the first shipment is received.
                    </li>
                    <li>
                      When purchasing food and beverage goods, no right of
                      withdrawal can be granted. Thus, seller cannot pass any
                      form of right of withdrawal when it comes to the purchase
                      of food and drink from the restaurant. This only applies
                      to cancellation of goods, which must be done quickly after
                      ordering goods and must be approved by the member
                      restaurant that has received that order.
                    </li>
                    <li>
                      If the purchase consists of several deliveries, the
                      withdrawal period will run from the day after the last
                      delivery has been received.
                    </li>
                  </ul>
                </p>
                <p>
                  The withdrawal period is extended to 12 months after the
                  expiry of the original deadline if the seller does not state
                  before the conclusion of the agreement that there is a right
                  of withdrawal and a standardised withdrawal form. The same
                  applies in the event of a lack of information about terms,
                  deadlines and the procedure for using the right of withdrawal.
                  If the trader makes sure to give up the information during
                  these 12 months, the withdrawal period still expires 14 days
                  after the day the buyer received the information.
                </p>
                <p>
                  The buyer can try or test the item in a prudent manner to
                  determine the nature, characteristics and function of the
                  item, without the right of withdrawal. If testing or testing
                  of the item goes beyond what is justifiable and necessary, the
                  buyer may be responsible for any reduced value of the item.
                </p>
                <p>
                  The seller is obliged to refund the purchase price - but to
                  the buyer without undue delay, and no later than 14 days from
                  the seller was notified of the buyer's decision to exercise
                  the right of withdrawal. The seller has the right to withhold
                  payment until he has received the goods from the buyer, or
                  until the buyer has presented documentation that the goods
                  have been sent back.
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">
                  9. Delay and non-delivery - buyer's rights and deadline for
                  filing claims
                </h4>
                <p>
                  If the seller does not deliver the goods or delivers it too
                  late in accordance with the agreement between the parties, and
                  this is not due to the buyer or circumstances on the buyer's
                  part, the buyer may, in accordance with the rules of Chapter 5
                  of the Consumer Purchase Act, in the circumstances withhold
                  the purchase price, demand fulfilment, terminate the agreement
                  and/or claim compensation from the seller.
                </p>
                <p>
                  In the event of a claim for breach of contract, the
                  notification should be in writing for evidence purposes (e.g.
                  e-mail).
                  <ul>
                    <li>
                      <b>Fulfilment</b>
                      <br />
                      <p>
                        The buyer can maintain the purchase and demand
                        fulfilment from the seller. However, the Buyer cannot
                        claim fulfilment if there is an obstacle that the seller
                        cannot overcome, or if fulfilment will entail such a
                        great disadvantage or cost to the Seller that it is in
                        material desideration in the buyer's interest in the
                        seller fulfilling. Should the difficulties fall away
                        within a reasonable time, the buyer may nevertheless
                        demand fulfilment.
                      </p>
                      <p>
                        The buyer loses his or her right to demand fulfilment if
                        he or she waits unreasonably long to make the claim.
                      </p>
                    </li>
                    <li>
                      <b>Elevation</b>
                      <br />
                      <p>
                        If the seller does not deliver the item at the time of
                        delivery, the buyer shall encourage the Seller to
                        deliver within a reasonable additional deadline for
                        fulfilment. If the seller does not deliver the item
                        within the additional deadline, the buyer can cancel the
                        purchase.
                      </p>
                      <p>
                        However, the buyer can cancel the purchase immediately
                        if the seller refuses to deliver the item. The same
                        applies if delivery at the agreed time was decisive for
                        the conclusion of the agreement, or if the buyer has
                        informed the seller that the time of delivery is
                        decisive.
                      </p>
                      <p>
                        If the item is delivered after the additional deadline
                        set by the consumer or after the time of delivery that
                        was decisive for the conclusion of the agreement, the
                        claim for cancellation must be made within 24 hours
                        after the buyer learned of the delivery.
                      </p>
                    </li>
                    <li>
                      <b>Substitute</b>
                      <br />
                      <p>
                        The buyer may claim compensation for any loss suffered
                        as a result of the delay. However, this does not apply
                        if the seller proves that the delay is due to
                        obstruction beyond seller's control that could not
                        reasonably have been taken into account at the time of
                        the agreement, avoided, or overcome the consequences of.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">
                  10. Lack of the item - buyer's rights and warranty deadline
                </h4>
                <p>
                  If there is a defect in the item, the buyer must within a
                  reasonable time (12 hours) after it was discovered or should
                  have been discovered, notify the seller that he or she will
                  plead with the defect. The buyer has always advertised in time
                  if it happens within 12 hours of the defect being discovered
                  or should have been discovered. Complaints can be made no
                  later than 12 hours after the buyer took over the item.
                </p>
                <p>
                  If the goods have a defect and this is not due to the buyer or
                  circumstances on the buyer's part, the buyer may, in
                  accordance with the rules of Chapter 6 of the Consumer
                  Purchase Act, in the circumstances withhold the purchase sum,
                  choose between correction and re-delivery, demand a price
                  discount, demand the agreement terminated and/or demand
                  compensation from the seller.
                </p>
                <p>
                  Complaints to the seller should be made in writing.
                  <ul>
                    <li>
                      <b>Correction or re-delivery</b>
                      <p>
                        The buyer can choose between claiming the defect
                        corrected or the delivery of similar things. The Seller
                        may nevertheless object to the buyer's claim if the
                        execution of the claim is impossible or the seller
                        incurs unreasonable costs. Rectification or replacement
                        shall be made within a reasonable time. In the initial
                        point, the Seller does not have the right to make more
                        than two remedial attempts for the same defect.
                      </p>
                    </li>
                    <li>
                      <b>Price reduction</b>
                      <p>
                        The Buyer may request an appropriate discount if the
                        item is not corrected or relinquished. This means that
                        the relationship between the discounted and the agreed
                        price corresponds to the relationship between the value
                        of the thing in defective and contractual condition. If
                        special reasons speak for this, the price refusal may
                        instead be set equal to the deficiency's significance
                        for the buyer.
                      </p>
                    </li>
                    <li>
                      <b>Elevation</b>
                      <p>
                        If the item has not been corrected or re-delivered, the
                        buyer may also cancel the purchase when the defect is
                        not immaterial
                      </p>
                    </li>
                  </ul>
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">
                  11. Seller's rights in the event of buyer's default
                </h4>
                <p>
                  If the buyer does not pay or fulfil the other obligations
                  under the agreement or the law, and this is not due to the
                  seller or circumstances on the part of the seller, the seller
                  may, in accordance with the rules of Chapter 9 of the Consumer
                  Purchase Act, in the circumstances withhold the item, demand
                  fulfilment of the agreement, demand the agreement terminated
                  and demand compensation from the buyer. The seller will also
                  be able to charge interest in case of late payment, debt
                  collection fees and a reasonable fee for unclaimed goods.
                  <ul>
                    <li>
                      <b>Fulfilment</b>
                      <p>
                        The Seller may maintain the purchase and require the
                        buyer to pay the purchase price. If the item is not
                        delivered, the seller loses his right if he waits an
                        unreasonably long time to make the claim.
                      </p>
                    </li>
                    <li>
                      <b>Elevation</b>
                      <p>
                        The Seller may terminate the agreement if there is
                        material payment default or other material breach on the
                        part of the Buyer. However, the seller cannot raise if
                        the full purchase price has been paid. If the seller
                        sets a reasonable additional deadline for fulfilment and
                        the buyer does not pay within this deadline, the seller
                        may cancel the purchase.
                      </p>
                    </li>
                    <li>
                      <p>
                        Interest in case of delayed payment/collection fee If
                        the buyer does not pay the purchase price in accordance
                        with the agreement, the seller can demand interest on
                        the purchase price according to the Interest on Delay
                        Act. In the event of non-payment, the claim may, after
                        prior notice, be sent to debt collection. The buyer can
                        then be held liable for fees according to the Debt
                        Collection Act.
                      </p>
                    </li>
                    <li>
                      <p>Fee for undelivered non-prepaid goods</p>
                    </li>
                  </ul>
                </p>
                <p>
                  If the buyer fails to collect unpaid goods, the seller may
                  charge the buyer a fee. The fee shall cover the seller's
                  actual expenses to deliver the item to the buyer. Such a fee
                  cannot be charged to buyers under the age of 18.
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">12. Guaranteed</h4>
                <p>
                  The warranty given by the seller or manufacturer gives the
                  buyer rights in addition to those the buyer already has under
                  mandatory legislation. A warranty within- thus bears no
                  limitations on the buyer's right to a complaint and claims for
                  delay or defects pursuant to paragraphs 9 and 10.
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">13. Personal data</h4>
                <p>
                  The data controller for collected personal data is the seller
                  and their member restaurant companies. Unless the buyer agrees
                  otherwise, the seller may, in accordance with the Personal
                  Data Act, only collect and store the personal data necessary
                  for the seller and their member restaurants to fulfil the
                  obligations under the agreement. The Buyer's personal data
                  will only be disclosed to others if it is necessary for the
                  Seller to complete the agreement with the Buyer, or in
                  statutory cases.
                </p>
              </div>
              <div className="row privacyText">
                <h4 className="privacyHeading">14. Conflict resolution</h4>
                <p>
                  Complaints are directed to the seller within a reasonable
                  time, cf. sections 9 and 10. The parties shall endeavour to
                  resolve any disputes amicably.
                </p>
                <p>
                  If this is not successful, the buyer can contact the Consumer
                  Council for mediation. The Consumer Council is available by
                  phone +47 23 400 500 or{" "}
                  <a
                    href="https://www.forbrukerradet.no/"
                    className="privacyLink"
                  >
                    www.forbrukerradet.no&nbsp;
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row privacy-header text-center d-flex justify-content-center">
            <h2 className="display-5 mb-4 ">CANCELLATION AND REFUND POLICY</h2>
          </div>
        </div>
        <div className="container nav_bg">
          <div className="row privacyText">
            <div className="col-10 mx-auto privacyText">
              <div className="row py-4 privacyText">
                <p>
                  <i>
                    Any Capitalized terms used but not defined herein shall have
                    the meaning assigned to them under the Terms of Use which
                    govern your use of our website{" "}
                    <a href="https://treedrive.no" className="privacyLink">
                      treedrive.no&nbsp;
                    </a>{" "}
                    (the
                    <b>Website</b>”) and our ‘TD Ordering’ application for
                    mobile and handheld devices (the “<b>App</b>”). The Website
                    and the App are jointly referred to as the “<b>Platform</b>
                    ”.
                  </i>{" "}
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">1. Customer Cancellation </h4>
                <p>
                  <ol>
                    <li>
                      As a general rule Buyer shall not be entitled to cancel
                      Order once placed.{" "}
                    </li>
                    <li>
                      Buyer have a time period of 30 minutes for the order to
                      get claimed in case of wrong item delivered.{" "}
                    </li>
                  </ol>
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">
                  2. Non-Customer Cancellation{" "}
                </h4>
                <div>
                  <ol>
                    <li>
                      <p>
                        TD Ordering reserves the right to collect a penalty for
                        the Orders constrained to be cancelled by TD Ordering
                        for reasons not attributable to TD Ordering, including
                        but not limited to:
                      </p>
                      <ol>
                        <li>
                          <p>
                            in the event if the address provided by Buyer is
                            either wrong or falls outside the delivery zone;
                          </p>
                        </li>
                        <li>
                          <p>
                            failure to contact Buyer by phone or email at the
                            time of delivering the Order booking;
                          </p>
                        </li>
                        <li>
                          <p>
                            failure to deliver Buyer Order due to lack of
                            information, direction or authorization from Buyer
                            at the time of delivery; or
                          </p>
                        </li>
                        <li>
                          <p>
                            unavailability of all the items ordered by Buyer at
                            the time of booking the Order; or
                          </p>
                        </li>
                        <li>
                          <p>
                            unavailability of all the items ordered by the Buyer
                            at the time of booking the Order. However, in the
                            unlikely event of an item in an Order being
                            unavailable, the Buyer will receive a message on the
                            phone number provided to us at the time of placing
                            the Order and inform Buyer of such unavailability.
                            In such an event Buyer will be entitled to cancel
                            the entire Order and shall be entitled to a refund
                            to an amount up to 100% of the Order value.
                          </p>
                        </li>
                        <img />
                      </ol>
                    </li>
                    <li>
                      <p>
                        In case of cancellations for the reasons attributable to
                        TD Ordering or the Merchant or PDP, TD Ordering shall
                        not collect any penalty from the Buyer.
                      </p>
                    </li>
                  </ol>
                  <p>
                    <b>Refunds</b>
                    <br />
                    <ol>
                      <li>
                        Buyer may be entitled to a refund for prepaid Orders. TD
                        Ordering retains the right to retain the penalty payable
                        by the Buyer in Section I (2) from the amount refundable
                        to him/her. The Buyer shall also be entitled to a refund
                        of proportionate value in the event packaging of an item
                        in an Order or the complete Order is either tampered or
                        damaged and the Buyer refuses to accept at the time of
                        delivery for the said reason;
                      </li>
                      <li>
                        Buyer may be entitled to a refund of upto 100% of the
                        Order value if PDP fails to deliver the Order due to a
                        cause attributable to either PDP or TD Ordering,
                        however, such refunds will be assessed on a case-to-case
                        basis by TD Ordering. If TD Ordering fails to deliver
                        the order in case (driver’s fault/vehicle damage/or any
                        other avoidable circumstance), the refund will be
                        generated by TD Ordering for which you will receive a
                        message on your email or telephone number. If you have
                        any doubt about the delivery, you can call directly to
                        the respective restaurant for more details.
                      </li>
                      <li>
                        Our decision on refunds shall be final and binding.
                      </li>
                      <li>
                        All refund amounts shall be credited to Buyer’s account
                        or credited in the TFL wallet as a coupon as may be
                        stipulated as per the payment mechanism of the Buyer’s
                        choice, the estimated timelines are detailed below.
                      </li>
                    </ol>
                    <p>
                      Provided the same is communicated to TD Ordering Customer
                      Care through the Platform before the Order is marked
                      delivered.
                    </p>
                    <Table
                      className="py-4"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                    />
                  </p>
                </div>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">
                  3. Specific Terms with respect to Use of Platform for purchase
                  of beverages
                </h4>
                <p>
                  <ol>
                    <li>
                      <b>Cancellation:</b> As a general rule the Buyer shall not
                      be entitled to cancel his/her Order once placed.
                    </li>
                    <li>
                      <b>Non-Customer Cancellation:</b> TD Ordering reserves the
                      right to collect the Buyer cancellation fee for the Orders
                      constrained to be cancelled by TD Ordering for reasons not
                      attributable to TD Ordering or Merchant, including but not
                      limited to:
                      <p>
                        <ol>
                          <li>
                            in the event if the address provided by the Buyer is
                            either wrong or falls outside the TD Ordering’s
                            delivery zone;
                          </li>
                          <li>
                            failure to contact the Buyer by phone or email at
                            the time of delivering the Order;
                          </li>
                          <li>
                            failure to deliver the Buyer’s Order due to lack of
                            information, direction or authorization from the
                            Buyer at the time of delivery; or
                          </li>
                          <li>
                            the Buyer’s failure to provide a valid OTP to the
                            PDP for receiving delivery
                          </li>
                        </ol>
                      </p>
                    </li>
                  </ol>
                </p>
                <p>
                  <b>Refunds</b>
                  <br />
                  The Buyer may be entitled to a refund for prepaid Orders, post
                  deduction of cancellation fee, if any for reasons mentioned
                  above or in a manner as deemed fit by TD Ordering in its sole
                  discretion, if refund has been requested due to the following
                  reasons:
                  <p>
                    <ol>
                      <li>
                        If the Order could not be delivered within 2 hours;
                      </li>
                      <li>
                        If the Merchant cancels the Order due to reasons not
                        attributable to the Buyer, including but not limited to
                        store being closed, non-availability of items, store not
                        servicing online Orders, overcrowding at store, etc.;
                      </li>
                      <li>
                        If TD Ordering cancels the Order due to reasons not
                        attributable to the Buyer, including but not limited to
                        non-availability of PDPs, etc.
                      </li>
                    </ol>
                  </p>
                  <p>
                    The Buyer may be entitled to refund due to the aforesaid
                    reasons upto 100% of the Order value depending upon the
                    nature of issue. TD Ordering reserves the right to consider
                    the cancellation and refund request and determine if such
                    cancellation and refund request satisfy any of the aforesaid
                    conditions, in which case TD Ordering shall process the
                    cancellation request and refund to Buyer.
                  </p>
                  <p>
                    <b>Important Note 1:</b> The Buyer shall verify his/her
                    Order and the products before providing OTP to the PDP and
                    any issue with respect to the product or Buyer’s Order shall
                    be notified immediately, and cancellation request shall be
                    placed before providing OTP to the PDP. Once OTP is provided
                    to the PDP, it shall be deemed that the Buyer has accepted
                    delivery of his/her Order and once he/she has accepted
                    delivery, the Buyer cannot cancel the Order and/or claim
                    refund.
                  </p>
                  <p>
                    <b>Important Note 2:</b> In case of the complaint of any
                    spurious product, the liability shall solely lie with the
                    Merchant selling the product. TD Ordering is merely
                    facilitating the transactions between the Merchant and the
                    Buyer and therefore, TD Ordering shall assume no liability
                    with respect to the products sold by the Merchant. Tree
                    drive strictly discourages dealing of any spurious product
                    on its Platform and shall reserve the right to report such
                    incidents to the concerned authorities for appropriate legal
                    actions. In case of unavoidable circumstances that cannot be
                    ignored e.g traffic obstruction, any emergency, we will not
                    able to compensate in that case .
                  </p>
                </p>
              </div>

              <div className="row privacyText">
                <h4 className="privacyHeading">
                  4. Genie’s cancellation policy{" "}
                </h4>
                <p>
                  <ol>
                    <li>
                      Cancellation of a Pick-up and Drop order after the
                      delivery partner has reached the location but has not
                      picked up the items may invite a cancellation fee in the
                      range of 30% of the bill amount.
                    </li>
                    <li>
                      Cancellation of a Pick-up and Drop order after the
                      delivery executive has picked up the delivery items will
                      result in a cancellation fee being charged equivalent to
                      the entire service fee.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BottomFooter />
    </>
  );
}
