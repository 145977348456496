import React, { useState } from "react";
import { Row, Col, Divider, Collapse } from "antd";
import { useNavigate } from "react-router";
import { Button, Modal, Card } from "react-bootstrap";
import giftCardToMe from "../../assets/giftYourdelf.png";
import giftCardToGift from "../../assets/gift meal.png";
import seeCard from "../../assets/seecard.png";
import { NavLink } from "react-router-dom";

import "aos/dist/aos.css";

function PopUpGiftCard() {
  let navigate = useNavigate();
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    navigate("/home");
    window.location.reload(false);
  };

  const { Panel } = Collapse;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="giftCardModal popupGiftCard"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "black" }}>Kjøp gavekort</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row className="mb-2">
          <p className="my-2">
            <Collapse>
              <Panel
                header="*TFL-gavekortet er vår måte å dele gleden av deilig..."
                key="1"
              >
                <p className="text-dark">
                  TFL-gavekortet er vår måte å dele gleden av deilig mat med
                  dine kjære. Det er et digitalt kort som lar mottakere nyte et
                  måltid på en hvilken som helst deltakende restaurant eller
                  detaljhandel i nettverket vårt. Du kan gi en valgfri gave,
                  slik at venner, familie eller kolleger kan velge
                  favorittrettene eller produktene deres. Enten for en bursdag,
                  jubileum eller en enkel takknemlighet, er TFL-gavekortet den
                  perfekte måten å vise at du bryr deg. Dine innsamlede digitale
                  TFL-mynter konverteres også til digitale TFL-kort, noe som gir
                  ekstra fleksibilitet. Det er utrolig enkelt å bruke –
                  mottakerne kan vise frem det digitale kortet ved kjøp, enten
                  de spiser på middag eller handler på nettet. Med vår sømløse
                  innløsningsprosess kan de nyte måltidet eller produktet
                  problemfritt. Hos Tree Drive AS tror vi på matens kraft for å
                  bringe mennesker sammen, og TFL-gavekortet er vår måte å spre
                  den gleden på.
                </p>
                <br />
              </Panel>
            </Collapse>
          </p>{" "}
          <Col span={24}>
            <Card
              onClick={() => {
                navigate("/getTflCoupon");
              }}
              className="mt-3 p-3 bg-green cardBg-green d-flex align-items-start"
            >
              <p className="text-dark">
                <b>Kjøp til deg selv/ familie/ venner</b>
              </p>
            </Card>{" "}
          </Col>
          <Col span={24}>
            <NavLink to="/profile?openTab=coupon">
              <Card className="mt-3 p-3 bg-green cardBg-brown mb-2 align-items-start">
                <p className="text-center text-white text-bold">
                  Se gavekortet ditt
                </p>
              </Card>
            </NavLink>
          </Col>
          <Divider />
          <Col span={24} className="mt-2">
            <h5 style={{ color: "#C99476", fontWeight: 900 }}>
              {" "}
              Noen gaver er store. Andre er små. Men de som kommer fra hjertet
              er den beste gaven av alle.
            </h5>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Greit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PopUpGiftCard;
