import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import playStore from "../../assets/googleplay.png";
import appStore from "../../assets/appstore.png";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled
} from "@ant-design/icons";

import Partnersbox from "../Partners/Partnersbox";
import aryan from "../../assets/aryan.png";
import tree from "../../assets/tfl.png";
import treeNet from "../../assets/treedrivenet.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Modal from "../Modal/Modal";
import Form from "../ContactForm/Form";

function BottomFooter() {
  const [isOpen, setisOpen] = useState(false);
  const [isOpenForm, setisOpenForm] = useState(false);
  const [isOpenForm2, setisOpenForm2] = useState(false);
  const buttonClicked1 = () => {
    setisOpenForm(true);
  };
  const buttonClicked2 = () => {
    setisOpenForm2(true);
  };
  const handleClose = () => {
    setisOpen(false);
  };
  const handleClose2 = () => {
    setisOpen(false);
  };
  const onCloseForm = () => {
    setisOpenForm(false);
  };
  const onCloseForm2 = () => {
    setisOpenForm2(false);
  };
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);
  const content = (
    <div>
      <b>
        Don’t fall for phishing attempts TD Ordering customer care will never
        request sensitive bank information from you - such incidents are scams
        and should be reported immediately
      </b>
      <p>
        Please do not share your debit/credit card number, CVV number, OTP,
        UPI/ATM pin, and so on with anyone claiming to be a TD Ordering
        representative. <br />
        TD Ordering does not have any customer care number or helpline. Tree
        Drive or its authorized representatives will NEVER ask you to share
        financial details. If you’ve encountered such an incident, please know
        that it is a fraud, and you must report it immediately to stay safe from
        a phishing attempt. For assistance on a TD Ordering order, click on the
        ‘Help’ section on the TD Ordering app - there is no other help channel
        available.
      </p>
    </div>
  );
  return (
    <Container
      fluid
      className="px-5 pt-5 BottomFooter position-relative"
      id="BottomFooter"
    >
      <Row className="px-5 pt-5 pb-3 justify-content-between">
        <Col sm={4} className="footerLink">
          <h3>Adresse</h3>
          <ul className="list-unstyled">
            <li>
              <span>
                TREE DRIVE AS
                <br />
                Storgata 16, 1531 Moss, Norway
              </span>
            </li>
            <li>
              <a
                href="mailto:kontakt@treedrive.no"
                className="footerLink"
                style={{ color: "white" }}
              >
                kontakt@treedrive.no
              </a>
            </li>
            <li>
              {" "}
              <a href="tel:+47 484 29 523" className="footerLink">
                +47 484 29 523
              </a>
            </li>
          </ul>
        </Col>
        <Col sm={3} className="footerLink">
          <h3>Andre linker</h3>
          <ul className="list-unstyled">
            <li>
              <a href="/privacy" className="footerLink">
                Personvern Politikk
              </a>
            </li>
            <li>
              <a href="/terms" className="footerLink">
                T&C
              </a>
            </li>
            <li>
              <a href="/salesAndCancellation" className="footerLink">
                Sales T&C
              </a>
            </li>
            <li>
              <a href="/scamAlert" className="footerLink">
                Svindelvarsel
              </a>
            </li>
            <li>
              <a href="/faqs" className="footerLink">
                FAQ's
              </a>
            </li>
          </ul>{" "}
        </Col>
        <Col sm={3} className="footerLink ">
          <div>
            <h3>Registrer deg som</h3>
            <ul className="list-unstyled">
              <li>
                <a
                  href="https://treedrive.net/"
                  className="footerLink"
                  target="_blank"
                >
                  Forretningspartner
                </a>
              </li>
              <li>
                <a className="footerLink" onClick={() => buttonClicked2()}>
                  Sjåførpartner
                </a>
              </li>
            </ul>
          </div>
        </Col>
        <Col sm={8} className="footerLink ">
          <h3>Sosiale lenker</h3>
          <ul className="list-unstyled">
            <li className="socialIcons mb-2">
              <a
                href="https://www.facebook.com/Tree-Drive-104954908733445"
                target="_blank"
              >
                <FacebookFilled />
              </a>{" "}
              <a
                href="https://instagram.com/_treedrive?igshid=MzRlODBiNWFlZA=="
                target="_blank"
              >
                <InstagramFilled />
              </a>
              <a
                href="https://www.linkedin.com/company/83513109/admin/feed/posts/"
                target="_blank"
              >
                <LinkedinFilled />
              </a>
            </li>
          </ul>
        </Col>
        <Col sm={3} className="footerLink">
          <div>
            <h3>Samarbeidspartner</h3>
            <ul className="list-unstyled">
              <li className="d-flex">
                <Partnersbox image={aryan} />
                <Partnersbox image={tree} /> <Partnersbox image={treeNet} />{" "}
              </li>
            </ul>
          </div>
        </Col>
        <Col sm={3} className="footerLink">
          <h3>Last ned appen</h3>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.omvadata.treedriveuser"
              target="_blank"
            >
              <img src={playStore} width="150px" />
            </a>
            <a
              href="https://apps.apple.com/in/app/tree-drive/id6444897318"
              target="_blank"
            >
              <img src={appStore} width="150px" />
            </a>
          </div>
        </Col>
      </Row>
      <Row className="pt-5 pb-0 px-5">
        <Col span={12}>
          {" "}
          <h6 className="text-white py-4 m-0">
            {" "}
            Copyright ©2024 TD Ordering. Alle Rettigheter Forbeholdes.
          </h6>
        </Col>
        <Col
          span={12}
          className="d-flex align-items-center justify-content-end"
        >
          {" "}
          <a href="/disclaimer" className="footerLink">
            Disclaimer
          </a>
        </Col>{" "}
      </Row>
      <Modal isOpen={isOpen} handleClose={handleClose} />{" "}
      {isOpenForm && <Form onCloseForm={onCloseForm} />}
      {isOpenForm2 && <Form onCloseForm2={onCloseForm2} />}
    </Container>
  );
}
export default BottomFooter;
