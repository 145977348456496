import { Modal, Form, Button } from "react-bootstrap";
import { Row, Collapse } from "antd";

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { saveCoupons } from "../../store/actions/couponAction";
import { NavLink } from "react-router-dom";

function ProductModal(props) {
  const { Panel } = Collapse;

  const onChange = (key) => {
    console.log(key);
  };
  const [show, setShow] = useState(true);
  const [isDisabled, setisDisabled] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  let emailRef = useRef("");
  let confirmEmailRef = useRef("");
  let coupanValueRef = useRef("");

  let countrycodeRef = useRef("");
  let phoneNumberRef = useRef("");
  let confirmphoneNumberRef = useRef("");

  let [errorObj, setErrorObj] = useState({
    emailError: false,
    couponError: false,
    phoneNumberError: false,
    confirmphoneNumberError: false,
    coupanValueError: false,
    conifirmEmailError: false
  });

  let [confirmCountryCode, setCountryCodeConfirm] = useState("");

  const onSubmitCoupon = () => {
    let isFormVaild = true;
    setisDisabled(true);
    if (emailRef.current.value == "" && !emailRef.current.value.includes("@")) {
      isFormVaild = false;
      setErrorObj((errState) => {
        return { ...errState, emailError: true };
      });
    }
    if (confirmEmailRef.current.value != emailRef.current.value) {
      isFormVaild = false;
      setErrorObj((errState) => {
        return { ...errState, conifirmEmailError: true };
      });
    }
    if (phoneNumberRef.current.value.length < 6) {
      isFormVaild = false;
      setErrorObj((errState) => {
        return { ...errState, phoneNumberError: true };
      });
    }

    if (confirmphoneNumberRef.current.value != phoneNumberRef.current.value) {
      isFormVaild = false;
      setErrorObj((errState) => {
        return { ...errState, confirmphoneNumberError: true };
      });
    }

    if (coupanValueRef.current.value == "") {
      isFormVaild = false;
      setErrorObj((errState) => {
        return { ...errState, coupanValueError: true };
      });
    }

    if (isFormVaild) {
      dispatch(
        saveCoupons({
          phoneNumber: phoneNumberRef.current.value,
          email: emailRef.current.value,
          confirmEmail: confirmEmailRef.current.value,
          couponValue: coupanValueRef.current.value,
          confirmphoneNumber: confirmphoneNumberRef.current.value,
          countryCode: countrycodeRef.current.value
        })
      )
        .then(() => {
          setisDisabled(false);

          props.closeGiftCardModal();
        })
        .catch(() => {
          setisDisabled(false);
          alert("something went wrong");
        });
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="giftCardModal">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Kjøp gavekort</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="mb-2">
            <p className="mb-2">
              <i>
                {" "}
                * Tree for life (TFL) gavekort er en måte å kjøpe matvarer til
                deg selv eller gi en gave til dine kjære. Den er forhåndslastet
                med ønsket pengebeløp. Kortet kan brukes når du bestiller online
                eller hos våre medlemsrestaurantene.
              </i>
            </p>
            <NavLink to="/profile?openTab=coupon">
              <p>See Your Gift Card</p>
            </NavLink>
          </Row>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Gift Card Value</Form.Label>
              <Form.Control type="number" ref={coupanValueRef} />
              {errorObj.coupanValueError && (
                <Form.Control.Feedback className="custom danger" type="invalid">
                  Vennligst skriv inn en gyldig verdi
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control type="email" ref={emailRef} />
              {errorObj.emailError && (
                <Form.Control.Feedback className="custom danger" type="invalid">
                  Vennligst skriv inn en gyldig e-mail adresse
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Confirm E-Mail</Form.Label>
              <Form.Control type="email" ref={confirmEmailRef} />
              {errorObj.conifirmEmailError && (
                <Form.Control.Feedback className="custom danger" type="invalid">
                  E-post samsvarer ikke
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Telefonnummer</Form.Label>
              <div className="d-flex ">
                <Form.Select
                  aria-label="Default select example"
                  style={{ width: "100px" }}
                  ref={countrycodeRef}
                  onChange={(event) => {
                    setCountryCodeConfirm(event.target.value);
                  }}
                >
                  <option value="47">+47</option>
                  <option value="91">+91</option>
                </Form.Select>
                <Form.Control
                  aria-label="Text input with dropdown button"
                  ref={phoneNumberRef}
                  type="number"
                />
              </div>

              {errorObj.phoneNumberError && (
                <Form.Control.Feedback className="custom danger" type="invalid">
                  Vennligst oppgi et gyldig telefonnummer.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Bekreft telefonnummer</Form.Label>
              <div className="d-flex ">
                <Form.Select
                  aria-label="Default select example"
                  style={{ width: "100px" }}
                  value={confirmCountryCode}
                >
                  <option value="47">+47</option>
                  <option value="91">+91</option>
                </Form.Select>
                <Form.Control
                  aria-label="Text input with dropdown button"
                  ref={confirmphoneNumberRef}
                  type="number"
                />
              </div>
              {errorObj.confirmphoneNumberError && (
                <Form.Control.Feedback className="custom danger" type="invalid">
                  Telefonnummer samsvarer ikke
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
          <Row>
            <Collapse onChange={onChange}>
              <Panel header="Hvordan det fungerer?" key="1">
                <ul>
                  <li>
                    <i>
                      Kjøp et kort via TD Ordering-appen eller nettstedet med
                      ønsket beløp.
                    </i>
                  </li>
                  <li>
                    <i>
                      Kjøperen mottar en e-post og sms med en unik kode,
                      samtidig kan se kortet i appen.
                    </i>
                  </li>
                  <li>
                    <i>
                      Den er lastet med beløpet du betaler eller mer avhengig av
                      tilbudet på det tidspunktet.
                    </i>
                  </li>
                  <li>
                    <i>
                      Bruk kort-koden når du bestiller matvarer fra en av
                      A-Boards medlemsrestauranter eller på Tree
                      drive-plattformer (app eller nettstedet).
                    </i>
                  </li>
                </ul>
              </Panel>
            </Collapse>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSubmitCoupon}>
            Kjøp gavekort
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProductModal;
