import logo from "./logo.svg";
import "./App.css";
import Cards from "./components/Cards/Cards";

import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Privacy/Terms";
import Cancellation from "./components/Privacy/Cancellation";

import TreeDrivePrivacy from "./components/AppPrivacy/TreeDrivePrivacy";
import TreeDriveTerms from "./components/AppPrivacy/TreeDriveTerms";

import TopBar from "./components/Header/TopBar";
import RestaurantList from "./components/RestaurantProducts/RestaurantList";
import RestaurantListComponent from "./components/Restaurants/ResturantListComponent";
import TflMarket from "./components/TFLMarket/TflMarket";
import TflMarketCart from "./components/TFLMarket/TflMarketCart";
import VendorsList from "./components/Vendor/VendorsList";
import VendorsProduct from "./components/Vendor/VendorsProduct";
import VendorsCheckout from "./components/Vendor/VendorCheckout";

import RetailerList from "./components/Retailer/RetailerList";
import RetailerProduct from "./components/Retailer/RetailerProduct";
import RetailerCheckout from "./components/Retailer/RetailerCheckout";
import RetailerPayStatus from "./components/Retailer/ReatilerPayStatus";

import Checkout from "./components/RestaurantProducts/Checkout";
import DriveOutCheckout from "./components/Checkout/DriveoutCheckout";
import OtpCompoent from "./components/Otp/Otp";
import { Route, Router, Routes, Switch } from "react-router";
import { Delivery } from "./components/RestaurantProducts/Delivery";
import { Pickup } from "./components/RestaurantProducts/Pickup";
import { OrderSummary } from "./components/RestaurantProducts/OrderSummary";
import { Product } from "./components/RestaurantProducts/Products/Product";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendLocationAction } from "./store/actions/locationaction";
import AddToCart from "./components/RestaurantProducts/AddToCart";
import OnlineOrder from "./components/OnlineOrderPage/OnlineOrder";
import TreeDriveIndex from "./components/Treedrive";
import EmpTy from "./components/EmptyPage/EmpTy";
import SuceessCouponPurchase from "./components/Coupon/BuyCoupon/SuceessCouponPurchase";
import SuccessCouponOrderSummary from "./components/RestaurantProducts/SuccessCoupon";
import { CouponPolicy } from "./components/Privacy/CouponPolicy";
import { ScanBarCode } from "./components/OnlineOrderPage/Scanner/ScanBarCode";
import SupportFrom from "./components/ContactForm/SupportFrom";
import ScamAlert from "./components/Privacy/ScamAlert";
import Disclaimer from "./components/Privacy/Disclaimer";

import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import Reset from "./components/Reset/Reset";
import Verify from "./components/Verify/Verify";
import Profile from "./components/Profile/Profile";
import TflGiftCard from "./components/TFL Gift Card/TflGiftCard";
import FAQs from "./components/Privacy/FAQs";
import TFLOrderSummary from "./components/TFLMarket/TFLOrderSummary";

function App() {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(sendLocationAction());
  }, []);

  return (
    <Routes>
      <Route path="/" element={<TreeDriveIndex />}>
        <Route path="home" element={<OnlineOrder />} />
        <Route path="/getTflCoupon" element={<TflGiftCard />} />
      </Route>

      <Route path="" element={<TopBar />}>
        <Route path="/restaurants" element={<RestaurantListComponent />} />
        <Route path="/tflMarket" element={<TflMarket />} />
        <Route path="/tflMarketcart" element={<TflMarketCart />} />
        <Route path="/TFLOrderSummary" element={<TFLOrderSummary />} />
        <Route path="/products" element={<Product />} />
        <Route path="/checkout" element={<DriveOutCheckout />} />
        <Route path="/orderstatus" element={<OrderSummary />} />
        <Route path="/VendorsList" element={<VendorsList />} />
        <Route path="/VendorsProduct/:id" element={<VendorsProduct />} />
        <Route path="/VendorsCheckout" element={<VendorsCheckout />} />
        <Route exact path="/restaurant/:slug" element={<Product />} />
        <Route path="/restaurantlist" element={<RestaurantList />} />
        <Route
          path="/orderstatuscoupon"
          element={<SuccessCouponOrderSummary />}
        />
        <Route path="/RetailerList" element={<RetailerList />} />{" "}
        <Route path="/RetailerProduct/:id" element={<RetailerProduct />} />
        <Route path="/RetailerCheckout" element={<RetailerCheckout />} />
        <Route path="/RetailerPayStatus" element={<RetailerPayStatus />} />
      </Route>
      <Route
        path="/RestaurantList/restaurantlist"
        element={<RestaurantList />}
      />

      <Route exact path="/privacy" element={<Privacy />} />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/tflgiftcardpolicy" element={<CouponPolicy />} />

      {/*---------------app policies start---------------------*/}
      <Route exact path="/treedriveprivacy" element={<TreeDrivePrivacy />} />
      <Route exact path="/treedriveterms" element={<TreeDriveTerms />} />
      {/*---------------app policies end---------------------*/}

      <Route path="/RestaurantList/checkout" element={<AddToCart />} />

      <Route path="/RestaurantList/delivery" element={<Delivery />} />
      <Route path="/RestaurantList/pickup" element={<Pickup />} />

      <Route path="/RestaurantList/ordersummary" element={<OrderSummary />} />

      {/*---------------payment screens end---------------------*/}
      {/* <Route exact path="/CancellationPolicy" element={<Cancellation />} /> */}
      <Route exact path="/salesAndCancellation" element={<Cancellation />} />
      <Route exact path="/scamAlert" element={<ScamAlert />} />
      <Route exact path="/disclaimer" element={<Disclaimer />} />
      {/*---------------empty page---------------------*/}
      <Route exact path="/EmptyPage" element={<EmpTy />} />

      {/*---------qrmenu scanner-----------*/}
      <Route path="/DineIn" element={<ScanBarCode />}></Route>

      {/*---------support form  -----------*/}
      <Route path="/TD-Support" element={<SupportFrom />}></Route>

      {/* --------------with privacy header-------------- */}
      <Route path="/faqs" element={<FAQs />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/verify" element={<Verify />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/reset" element={<Reset />} />
      <Route exact path="/profile" element={<Profile />} />
      <Route path="/couponpaymentstatus" element={<SuceessCouponPurchase />} />
    </Routes>
  );
}

export default App;
