import React from "react";
import BottomFooter from "../Footer/BottomFooter";
import { useEffect } from "react";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";
import { Row, Col, Card } from "antd";
import Accordion from "@mui/material/Accordion";
import { useNavigate } from "react-router";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import ContactForm from "../ContactForm/ContactForm";
import { isMobile } from "react-device-detect";

const FAQs = () => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const contentStyle = {
    margin: 0,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
    padding: "20px"
  };
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      {!isMobile && <PrivacyHeader />}
      <div className="p-5 foodBgElements" id="faq">
        <div
          className="text-center mb-4 pb-3"
          data-aos="fade"
          data-aos-duration="8000"
        >
          <h2 className="headerText">OFTE STILTE SPØRSMÅL</h2>
        </div>
        <Row className="justify-content-around">
          <Col span={12} style={{ padding: "5px" }}>
            <Accordion
              expanded={expanded === "panel121"}
              onChange={handleChange("panel121")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ color: "text.secondary" }}>
                  Hva TREE DRIVE AS Handler om?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Tree Drive AS er en mangefasettert tjenesteleverandør som
                  tilbyr matbestilling og levering, webtjenester inkludert
                  design, utvikling og digital markedsføring, IT-støtte og
                  vedlikehold, rådgivning og et skybasert POS-styringssystem.{" "}
                  <br />
                  Vi tar sikte på å møte ulike forretningsbehov med våre
                  omfattende løsninger, som sikrer sømløs drift og
                  kundetilfredshet. For å få tilgang til alle våre tjenester,
                  vennligst besøk{" "}
                  <b>
                    <u>
                      <i>
                        <a className="text-dark" href="www.treedrive.net">
                          www.treedrive.net
                        </a>
                      </i>
                    </u>
                  </b>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ color: "text.secondary" }}>
                  Hvilke tjenester tilbyr{" "}
                  <b>
                    <u>
                      <i>
                        <a href="www.treedrive.no" className="text-dark">
                          www.treedrive.no?
                        </a>
                      </i>
                    </u>
                  </b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  TD Ordering er en matbestillingsplattform som gir deg
                  muligheten til å få matlevering på døra med den beste
                  emballasjen, hente bestillingen selv og lar deg også reservere
                  bord i den valgte restauranten.
                  <br /> Vi tilbyr mat fra alle berømte medlems-restauranter i
                  nærheten av deg. Bestill mat med den beste brukeropplevelsen.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ color: "text.secondary" }}>
                  Hvordan legger jeg inn bestillingen min på TD Ordering?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Du kan velge direkte utkjøring, hente selv eller reservere
                  bord gjennom TD Ordering-plattformen. <br />
                  Velg og bekreft posisjonen din, og du kan få en liste over
                  kjente restauranter som serverer i nærområdet. <br />
                  Velg varer du vil legge til, eventuelle endringer og legg dem
                  til i handlekurven. Gå til handlekurven, legg til
                  betalingsinformasjon og bekreft bestillingen.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ color: "text.secondary" }}>
                  Hvordan sporer jeg bestillingen min?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Når restauranten har bekreftet bestillingen, vil du motta
                  bekreftelsen på den registrerte e-postadressen,
                  telefonnummeret eller kan spores direkte i appen.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Hva er TFL-gavekort?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  TFL-gavekorttjenesten er designet for å gi et måltid (er) med
                  et fast beløp til dine kjære, ansatte, til barnefester, etc.{" "}
                  <br /> Den kan brukes så ofte man vil, med mindre den ikke
                  verdsetter null. <br /> Når du kjøper et TFL-gavekort, vil du
                  eller personen du ønsker å gi en unik kode til motta koden via
                  e-post eller telefonnummer, som kan løses inn i TreeDrive
                  plattformen eller direkte hos våre medlems restaurantene ved å
                  fylle ut riktig kode. Dette er spesielt laget for å overraske
                  dine kjære med god mat og service.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Col>
          <Col span={12} style={{ padding: "5px" }}>
            {" "}
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: "100%", flexShrink: 0 }}>
                  Hvordan kan jeg få kontakt med TD Ordering-teamet?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Du kan kontakte teamet vårt ved å sende inn spørsmålet ditt på{" "}
                  <u>
                    <a href="mailto:kontakt@treedrive.no">
                      {" "}
                      kontakt@treedrive.no
                    </a>
                  </u>{" "}
                  eller ved å fylle ut et skjema på TD Ordering-hjemmesiden.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: "100%", flexShrink: 0 }}>
                  Hvordan kansellerer du bestillingen og søker om
                  refusjon/erstatning?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Du kan ikke kansellere bestillingen din etter at den er lagt
                  inn via TD Ordering, men det kan være mulig ved å kontakte
                  restauranten innen de første 2 minuttene etter at bestillingen
                  er lagt inn.
                  <br /> TD Ordering er ikke ansvarlig for noen form for
                  kansellering eller refusjon/erstatning av bestillingen, kan du
                  be om refusjon og erstatning til samme restaurant.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Kan jeg bestille 2 eller flere bestillinger samtidig?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Ja, du kan legge inn mer enn 2 bestillinger samtidig. Men ikke
                  fra 2 forskjellige restauranter samtidig.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: "100%", flexShrink: 0 }}>
                  Hva skjer hvis jeg ikke er der når leveringsansvarlig
                  ankommer?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  I dette tilfellet må du kontakte restauranten eller sjåfør for
                  å avtale om å slippe varene i nærheten av leveringsadressen.
                  Hvis du ikke tar imot varene eller ikke bekrefter OTP, vil
                  bestillingen automatisk kanselleres og ingen forespørsel om
                  refusjon/erstatning vil bli underholdt.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Col>
        </Row>
        {/* <Row>
          <ContactForm />
        </Row> */}
        <div className="m-4"></div>
      </div>

      <BottomFooter />
    </>
  );
};

export default FAQs;
