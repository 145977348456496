import { Col, Row } from "antd";
import { useEffect } from "react";
import { Outlet } from "react-router";
import MobileNavBar from "../NavbarHeader/MobileNavBar";
import { redirect, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

import { isMobile } from "react-device-detect";

let TreeDriveIndex = () => {
  let navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (location.pathname == "/") {
      return navigate("/home");
    }
  }, []);
  return (
    <>
     {!isMobile && (
     <MobileNavBar />
     )}
     <Outlet/>
    </>
  );
};

export default TreeDriveIndex;
